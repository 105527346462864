<template>
    <div>
        <div class="col-md-12">
            <div class="row" style="margin-top:20px">
                <div class="col-md-6">
                    <h3 class="page-header">
                        <i class="fa fa fa-user animated bounceInDown show-info"></i>
                        Party Ledger
                    </h3>
                </div>
                <div class="col-md-6 ">
                    <div class="row">
                        <!-- <div class="col-md-10">
                            <div class="row" style="padding-top:18px;justify-content:flex-end;" v-if="viewno == 1">
                                <div class="col-md-2" style="margin-right:25px;">
                                    <button @click="refresh()" class="btn btn-primary"><i class="fa fa-refresh"></i>
                                        Refresh</button>
                                </div>
                            </div>
                            <div class="row" style="padding-top:18px;justify-content:flex-end;" v-if="viewno == 3">
                                <div class="col-md-2" style="margin-right:25px;">
                                    <button @click="back()" class="btn btn-primary">Back</button>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="col-md-12 row mb-10">
                <div class="col-md-6 row">
                    <div class="col-md-4 text-center">
                        <label>Select Party</label>
                    </div>
                    <div class="col-md-8">
                        <select class="form-control form-cascade-control input-small .dropdown-menu" v-model="partyid">
                            <option value="0">All</option>
                            <option v-for="item in allparty" :key="item.id" :value="item.id">{{ item.firmname }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-6 row">
                    <div class="col-md-4 text-center">
                        <label>History</label>
                    </div>
                    <div class="col-md-8">
                        <select class="form-control form-cascade-control input-small .dropdown-menu" v-model="report">
                            <option value="0">Select</option>
                            <option value="1">Purchase History</option>
                            <option value="2">Payment History</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-md-12 row">
                <div class="col-md-6 row">
                    <div class="col-md-4 text-center">
                        <label>Date : From</label>
                    </div>
                    <div class="col-md-8">
                        <input type="date" class="form-control form-cascade-control input-small" v-model="fromdate" />
                    </div>
                </div>
                <div class="col-md-6 row">
                    <div class="col-md-4 text-center">
                        <label>Date : To</label>
                    </div>
                    <div class="col-md-8">
                        <input type="date" class="form-control form-cascade-control input-small" v-model="todate" />
                    </div>
                </div>
            </div>
            <div class="text-center">
                <button class="btn bg-success text-white username btnsearch" @click="search()">Search</button>
            </div>
        </div>
        <div class="col-md-12 row text-center mt-20">
            <div class="col-md-4 ">
                <div class="small_blog">
                    <label>Total Purchase : </label>
                    <span class="total ml-5">
                        <i class='bx bx-rupee'></i>
                        {{ purchaseamount }}
                    </span>
                </div>

            </div>
            <div class="col-md-4 ">
                <div class="small_blog">
                    <label>Paid Amount : </label>
                    <span class="total ml-5">
                        <i class='bx bx-rupee'></i>
                        {{ paidamount }}
                    </span>
                </div>
            </div>
            <div class="col-md-4 ">
                <div class="small_blog">
                    <label>Remaining Amount : </label>
                    <span class="total ml-5">
                        <i class='bx bx-rupee'></i>
                        {{ remaining }}
                    </span>
                </div>
            </div>

        </div>
        <template v-if="this.status > 0">
            <div class="col-md-12">
                <div class="panel">
                    <div class="panel-body" style="background: none;">
                        <div v-if="transaction.length > 0">
                            <table v-if="this.status == 1"
                                class="table table-bordered table-hover table-striped display"
                                style="background: #fff;">
                                <thead class="table-font" style="background: #475c78;color: #fff;">
                                    <th class="visible-lg">#</th>
                                    <th>Party name</th>
                                    <th>Invoice no.</th>
                                    <th>Transaction Date</th>
                                    <th>Invoice amt.</th>
                                    <th>Left Amt.</th>
                                </thead>
                                <tbody class="table-font">
                                    <tr v-for="(item, index) in transaction" :key="item.id">
                                        <!-- <td>{{((currentpage-1) * noofrec) + (index+1)}}</td> -->
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ item.user.firmname }}</td>
                                        <td>{{ item.invoiceid }}</td>
                                        <td>{{ item.transactiondate }}</td>
                                        <td>{{ item.amountwithtax }}</td>
                                        <td>{{ item.leftamount == -1 ? item.amountwithtax : item.leftamount }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <table v-if="this.status == 2"
                                class="table table-bordered table-hover table-striped display" style="background: #fff;"
                                id="tblmanual">
                                <thead class="table-font" style="background: #475c78;color: #fff;">
                                    <th class="visible-lg">#</th>
                                    <th>Party name</th>
                                    <th>Against Bill no.</th>
                                    <th>Transaction Date</th>
                                    <th>Paid amt.</th>
                                    <th>Mode</th>
                                    <th>Remark</th>
                                    <!-- <th>Balance</th> -->
                                </thead>
                                <tbody class="table-font">
                                    <tr v-for="(item, index) in transaction" :key="item.id">
                                        <!-- <td>{{((currentpage-1) * noofrec) + (index+1)}}</td> -->
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ item.customer.firmname }}</td>
                                        <td> {{ item.purchasetransaction?.invoiceid }}</td>
                                        <td>{{ item.transactiondate }}</td>
                                        <td>{{ item.amount }}</td>
                                        <td>{{ item.paymentregister?.name }}</td>
                                        <td>{{ item.remark }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else>
                            <div class="col-md-12 norecord">
                                <div style="font-weight: bold;font-size: 30px;font-family: system-ui;">No record found.
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-12 mt-20" >
                            <nav aria-label="Page navigation example" style="text-align: right !important">
                                <paginate style="margin: 0px !important; float: right" :page-count="noofpages"
                                    :click-handler="clickCallback" :prev-text="'&laquo;'" :next-text="'&raquo;'"
                                    :container-class="'pagination'">
                                </paginate>
                            </nav>
                        </div> -->
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
// import Paginate from "vuejs-paginate";
import moment from 'moment';
import Constants from '../../components/utilities/Constants.vue';
export default {
    // components: {    Paginate },
    mixins: [Constants],
    props: ['id'],
    data() {
        return {
            currentpage: 1,
            noofrec: 10,
            count: 1,
            rowcount: 0,
            allparty: [],
            partyid: 0,
            report: 0,
            moment: moment,
            fromdate: moment().format('YYYY-MM-DD'),
            todate: moment().format('YYYY-MM-DD'),
            transaction: [],
            status: 0,
            remaining: 0,
            paidamount: 0,
            purchaseamount: 0,


        }
    },
    computed: {
        ...mapGetters(['parties', 'selectedstoreid']),
        noofpages() {
            return Math.ceil(parseInt(this.rowcount) / parseInt(this.noofrec))
        }
    },

    mounted() {

        this.refresh();
    },
    methods: {
        clickCallback(currentpage) {
            this.currentpage = currentpage
            this.$store.commit("assigncurrentpage", currentpage);
            this.refresh()
        },
        refresh() {
            this.$store.commit('assignloadingstatus', 1)
            this.$store.dispatch("fetchparties")
            setTimeout(() => {
                this.allparty = this.parties
                this.$store.commit('assignloadingstatus', 0)
            }, 500)

        },
        search() {
            if (this.report == 0) {
                this.$notify({ text: 'Select history', type: 'warn' })
            } else {
                let param = {
                    partyid: this.partyid, report: this.report, fromdate: this.fromdate, todate: this.todate,
                    storeid: this.selectedstoreid
                    //  fy: this.getCurrentFinancialYear(),
                }
                console.log(param)
                window.axios.post('api/payment/purchase/transaction', param)
                    .then((response) => this.processTransactionResponse(response.data))
                    .catch((error) => console.log(error))
            }
        },
        processTransactionResponse(data) {
            this.status = data.status
            if (this.status == 1) {
                this.transaction = data.purchase
            } else if (this.status == 2) {
                this.transaction = data.payments
            }
            this.remaining = this.getRemainingAmount(data.purchase)
            this.paidamount = this.getPaidAmount(data.payments)
            this.purchaseamount = this.getTotalPurchase(data.purchase)
        },
        getRemainingAmount(item) {
            let total = 0
            if (item != null) {
                item.forEach((element) => {
                    if (element.leftamount == -1) {
                        total = total + parseFloat(element.amountwithtax)
                    } else {
                        total = total + parseFloat(element.leftamount)
                    }
                });
            }
            return total;
        },
        getPaidAmount(item) {
            let total = 0
            if (item != null) {
                item.forEach((element) => {
                    if (element.amount > 0) {
                        total = total + parseFloat(element.amount)
                    }
                })
            }
            return total
        },
        getTotalPurchase(item) {
            let total = 0
            if (item != null) {
                item.forEach((element) => {
                    if (element.amountwithtax > 0) {
                        total = total + parseFloat(element.amountwithtax)
                    }
                })
            }
            return total
        }
    }
}
</script>

<style scoped>
.btn-primary:hover {
    text-decoration: none !important;
    background-color: #3a476b !important;
}
</style>